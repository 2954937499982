import { enableProdMode, ErrorHandler, importProvidersFrom, LOCALE_ID } from '@angular/core';
import es from '@angular/common/locales/es';
import { environment } from './environments/environment';
import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/00-app/app.component';
import { PreloadAllModules, provideRouter, Router, RouteReuseStrategy, withPreloading } from '@angular/router';
import { IonicRouteStrategy, provideIonicAngular } from '@ionic/angular/standalone';
import { routes } from './app/app-routes';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptors, withInterceptorsFromDi } from '@angular/common/http';
import { TokenInterceptor } from './app/05-shared/interceptors/token.interceptor';
import { OfflineInterceptor } from './app/05-shared/interceptors/offline.interceptor';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { registerLocaleData } from '@angular/common';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { IonicStorageModule } from '@ionic/storage-angular';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import Bugsnag from '@bugsnag/js'
import BugsnagPerformance from '@bugsnag/browser-performance';
import 'hammerjs';
import { loadingInterceptor } from './app/05-shared/interceptors/loading.interceptor';
import { BugsnagErrorHandler } from './app/05-shared/utils/BugsnagErrorHandler';
import { ActionPerformed, LocalNotifications } from '@capacitor/local-notifications';
import { provideFirebaseApp, initializeApp } from '@angular/fire/app';
import { provideMessaging, getMessaging } from '@angular/fire/messaging';
import { firebaseConfig } from './environments/firebase-config';
import { Drivers } from '@ionic/storage';

import { FileOpener } from '@ionic-native/file-opener/ngx';

defineCustomElements(window);

if (environment.production) {
  enableProdMode();
}

// Función para configurar el listener de notificaciones
function setupNotificationListeners(router: Router) {
  // Agregar listener para acciones de notificaciones
  LocalNotifications.addListener('localNotificationActionPerformed', (notification: ActionPerformed) => { // Cambio aquí
    console.log('Acción realizada en la notificación: ', notification);

    // Obtener el ID de la acción que se realizó
    const actionId = notification.actionId;

    switch (actionId) {
      case 'ACCEPT':
        console.log('El usuario aceptó la notifiación');
        // Lógica para aceptar la reunión
        break;

      case 'DECLINE':
        console.log('El usuario rechazó la notificación');
        // Lógica para rechazar la reunión
        break;

      case 'SNOOZE':
        const inputValue = notification.inputValue; // Capturar valor ingresado si hay input
        console.log(`El usuario pospuso la reunión: ${inputValue} minutos`);
        // Lógica para posponer la reunión
        break;

      default:
        console.log('Acción no reconocida');
        break;
    }
  });
}


registerLocaleData(es);

// configure Bugsnag ASAP
Bugsnag.start({
  apiKey: '97922316a2bc008c96811b9a9e520ed1',
  appVersion: environment.versionNumber,
  appType: environment.versionDate
})
BugsnagPerformance.start({ apiKey: '97922316a2bc008c96811b9a9e520ed1' })

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

bootstrapApplication(AppComponent, {
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    FileOpener,
    provideIonicAngular({
      innerHTMLTemplatesEnabled: true,
    }),
    // Proveedores de Firebase sin `importProvidersFrom`
    provideFirebaseApp(() => initializeApp(firebaseConfig)),
    provideMessaging(() => getMessaging()),
    provideRouter(routes, withPreloading(PreloadAllModules)),
    { provide: LOCALE_ID, useValue: 'es-ES' },
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: OfflineInterceptor,
      multi: true,
    },
    {
      provide: 'APP_INITIALIZER',
      useFactory: (router: Router) => () => setupNotificationListeners(router),
      deps: [Router],
      multi: true,
    },
    { provide: ErrorHandler, useClass: BugsnagErrorHandler },
    provideHttpClient(withInterceptorsFromDi(), withInterceptors([loadingInterceptor])),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }).providers,
    importProvidersFrom(IonicStorageModule.forRoot(
      {
        driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage]
      }
    ))
  ],

});
