import { inject, Injectable } from '@angular/core';
import { Network } from '@capacitor/network';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http'; 
import { API_URL, VERSION } from "../../environments/environment";

export enum ConnectionStatus {
  Online,
  Offline
}

@Injectable({
  providedIn: 'root'
})
export class NetworkService {

  private status: BehaviorSubject<ConnectionStatus> = new BehaviorSubject<ConnectionStatus>(ConnectionStatus.Offline);
  private lineModeisOffSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private http = inject(HttpClient);

  constructor( ) {
    this.initializeNetworkStatus();
  }

  private async initializeNetworkStatus() {
    // Obtener el estado de la red al inicio
    const initialStatus = await Network.getStatus();
    this.updateNetworkStatus(initialStatus.connected ? ConnectionStatus.Online : ConnectionStatus.Offline);

    // Establecer un listener para cambios en la red
    Network.addListener('networkStatusChange', (status) => {
      this.updateNetworkStatus(status.connected ? ConnectionStatus.Online : ConnectionStatus.Offline);
    });
  }

  private updateNetworkStatus(status: ConnectionStatus) {
    this.status.next(status);

    let connection = status == ConnectionStatus.Offline ? 'Offline' : 'Online';
    // Mensaje opcional para informar al usuario sobre el cambio de estado de red
    //console.log(`You are now ${connection}`);
  }

  public getCurrentNetworkStatus(): ConnectionStatus {
    return this.status.getValue();
  }

  public get isConnected(): Observable<ConnectionStatus> {
    return this.status;
  }

  public getVersionAPI(): Observable<any> {
    const url = `${API_URL}/${VERSION}/versionAPI/version`;
    return this.http.get<any>(url);
  }

  setOfflineMode(value:boolean) {
    this.lineModeisOffSubject.next(value);
    this.status.next(value ? ConnectionStatus.Offline : ConnectionStatus.Online);
  }

  get lineModeisOff(): Observable<boolean> {
    return this.lineModeisOffSubject;
  }

}
