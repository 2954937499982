import { Injectable, inject } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { API_URL, VERSION } from "../../environments/environment";
import { Observable, from } from "rxjs";
import { ServiceSvContract } from '../01-models';
import { map } from "rxjs/operators";
import { NetworkService, ConnectionStatus } from './network.service';
import { OfflineManagerService } from './offline-manager.service';
import { ToastService } from './toast.service';
import { StorageService } from './storage.service';
import { Filesystem, Directory, WriteFileResult, FileInfo, StatResult } from '@capacitor/filesystem';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { LoadingService } from "./loading.service";
import { Capacitor } from '@capacitor/core';

const SERVICE_SV_CONTRACT_KEY = 'service-sv-contract';

@Injectable({
  providedIn: 'root'
})
export class ServiceSvContractService {
  private http = inject(HttpClient);
  private networkService = inject(NetworkService);
  private offlineManager = inject(OfflineManagerService);
  private toastService = inject(ToastService);
  private storageService = inject(StorageService);
  public loadingService = inject(LoadingService);
  private fileOpener = inject(FileOpener);

  getServiceSvContract(): Observable<ServiceSvContract[]> {
    const url = `${API_URL}/${VERSION}/ServiceSvContract/servicesvcontracts`;
    return this.http.get<ServiceSvContract[]>(url).pipe(
      map(serviceSvContract => {
        this.storageService.set(SERVICE_SV_CONTRACT_KEY, serviceSvContract);
        return serviceSvContract;
      })
    );
  }


  getAllServiceSvContract(): Observable<ServiceSvContract[]> {
    // Obtener los contratos de servicio desde el almacenamiento
    return from(this.storageService.get(SERVICE_SV_CONTRACT_KEY)).pipe(
      map(serviceSvContracts => {
        // Ordenar los contratos de servicio por año, luego por mes, y finalmente por nombre de la empresa
        if (serviceSvContracts == null) return [];
        const sortedServices = serviceSvContracts.sort((a, b) => {
          return b.year - a.year || b.month - a.month || a.companyName.localeCompare(b.companyName);
        });

        // Eliminar duplicados agrupando los contratos por 'id'
        const uniqueServices = [];
        sortedServices.forEach(service => {
          // Si no existe un servicio con el mismo 'id', lo añadimos a la lista de servicios únicos
          if (!uniqueServices.some(existingService => existingService.id === service.id)) {
            uniqueServices.push(service);
          }
        });

        // Retornar la lista de contratos de servicio únicos y ordenados
        return uniqueServices;
      })
    );
  }


  getServiceSvContractByDni(workerDni: string): Observable<ServiceSvContract[]> {
    return from(this.storageService.get(SERVICE_SV_CONTRACT_KEY)).pipe(
      map(serviceSvContract => serviceSvContract.filter(serviceSaleOrder => workerDni && serviceSaleOrder.workerDni == workerDni))
    );
  }

  getServiceSvContractBySubstituteDni(workerDni: string): Observable<ServiceSvContract[]> {
    return from(this.storageService.get(SERVICE_SV_CONTRACT_KEY)).pipe(
      map(serviceSvContract => serviceSvContract.filter(serviceSaleOrder => workerDni && serviceSaleOrder.substitutes && serviceSaleOrder.substitutes.indexOf(workerDni) !== -1))
    );
  }

  getServiceSvContractByIds(ids: string[]): Observable<ServiceSvContract[]> {
    return from(this.storageService.get(SERVICE_SV_CONTRACT_KEY)).pipe(
      map(serviceSvContract => serviceSvContract.filter(serviceSaleOrder => ids.includes(serviceSaleOrder.serviceSvRef)))
    );
  }

  getServiceSvContractByWorker(workerDni: string): Observable<ServiceSvContract[]> {
    return from(this.storageService.get(SERVICE_SV_CONTRACT_KEY)).pipe(
      map(serviceSvContract => {
        const services = serviceSvContract.filter(serviceSaleOrder => (workerDni && serviceSaleOrder.dni == workerDni) || (workerDni && serviceSaleOrder.substitutes && serviceSaleOrder.substitutes.indexOf(workerDni) !== -1))
          .sort((a, b) => (b.year - a.year || b.month - a.month || a.companyName.localeCompare(b.companyName)))

        const servicesGroupByWorkplace = [];
        services.forEach(service => {
          if (!servicesGroupByWorkplace.find(s => s.id === service.id)) servicesGroupByWorkplace.push(service);
        });
        return servicesGroupByWorkplace;
      })
    );
  }

  serviceSvContractByTeamLead(workerDniLead: string/*, serviceIds: string[]*/): Observable<ServiceSvContract[]> {

    // Obtener los contratos de servicio desde el almacenamiento

    return from(this.storageService.get(SERVICE_SV_CONTRACT_KEY)).pipe(
      map((serviceContracts: ServiceSvContract[]) => {
        // Filtrar los contratos de servicio basados en las siguientes condiciones:

        const filteredServices = serviceContracts.filter(serviceSvContract => {
          const isLead = workerDniLead && serviceSvContract.dni === workerDniLead;
          const isSubstitute = workerDniLead && serviceSvContract.substitutes?.includes(workerDniLead);
          //const isReferenced = serviceIds.includes(serviceSvContract.serviceSvRef);
          const isTeamLead = serviceSvContract.teamLeads?.includes(workerDniLead);

          // Retornar verdadero si cumple alguna de las condiciones anteriores
          return isLead || isSubstitute /*|| isReferenced*/ || isTeamLead;
        });

        // Ordenar los contratos de servicio por año, luego por mes, y finalmente por el nombre de la empresa
        const sortedServices = filteredServices.sort((a, b) => {
          return b.year - a.year || b.month - a.month || a.companyName.localeCompare(b.companyName);
        });

        // Eliminar duplicados agrupando por 'id' del contrato de servicio
        const uniqueServices = [];
        sortedServices.forEach(service => {
          // Si no existe un servicio con el mismo 'id', lo añadimos al arreglo
          if (!uniqueServices.some(existingService => existingService.id === service.id)) {
            uniqueServices.push(service);
          }
        });

        // Devolver los contratos de servicio únicos y ordenados
        return uniqueServices;
      })
    );
  }

  getServiceSvContractByIdAndDni(id: string, workerDni: string): Observable<ServiceSvContract> {
    return from(this.storageService.get(SERVICE_SV_CONTRACT_KEY)).pipe(
      //map(serviceSvContract => serviceSvContract.find(serviceSaleOrder => workerDni && serviceSaleOrder.workerDni == workerDni && serviceSaleOrder.id == id))
      map(serviceSvContract => serviceSvContract.find(serviceSaleOrder => serviceSaleOrder.id == id))
    );
  }

  getServiceSvContractById(id: string): Observable<ServiceSvContract> {
    return from(this.storageService.get(SERVICE_SV_CONTRACT_KEY)).pipe(
      map(serviceSvContract => serviceSvContract.find(serviceSaleOrder => serviceSaleOrder.id == id))
    );
  }

  getServiceSvContractByCompanyId(companyId: number): Observable<ServiceSvContract[]> {
    return from(this.storageService.get(SERVICE_SV_CONTRACT_KEY)).pipe(
      map(serviceSvContract => {
        const services = serviceSvContract.filter(serviceSaleOrder => serviceSaleOrder.companyId == companyId)
          .sort((a, b) => (b.year - a.year || b.month - a.month || a.companyName.localeCompare(b.companyName)))

        const servicesGroupByWorkplace = [];
        services.forEach(service => {
          if (!servicesGroupByWorkplace.find(s => s.id === service.id)) servicesGroupByWorkplace.push(service);
        });
        return servicesGroupByWorkplace;
      })
    );
  }

  getServiceSvContractByWorkplace(companyId: number, workplaceNum: number): Observable<ServiceSvContract[]> {
    return from(this.storageService.get(SERVICE_SV_CONTRACT_KEY)).pipe(
      map(serviceSvContract => serviceSvContract.filter(serviceSaleOrder => serviceSaleOrder.companyId == companyId && serviceSaleOrder.workplaceNum == workplaceNum))
    );
  }

  update(serviceSaleOrder: ServiceSvContract) {
    const url = `${API_URL}/${VERSION}/ServiceSvContract/update`;
    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline) {
      this.offlineManager.storeRequest(url, 'POST', serviceSaleOrder);
    } else {
      this.http.post(url, serviceSaleOrder).subscribe(
        response => {
          //console.log(response)
        },
        error => {
          //console.log(error)
          this.toastService.error(error.message);
          this.offlineManager.storeRequest(url, 'POST', serviceSaleOrder);
        }
      );
    }
    return from(this.updateServiceSvContract(serviceSaleOrder));
  }

  updateServiceSvContract(serviceSaleOrder: ServiceSvContract) {
    return this.storageService.get(SERVICE_SV_CONTRACT_KEY).then((serviceSvContracts: ServiceSvContract[]) => {
      const index = serviceSvContracts.findIndex(serviceSvContractItem => serviceSvContractItem.id === serviceSaleOrder.id);
      serviceSvContracts[index] = serviceSaleOrder;
      return this.storageService.set(SERVICE_SV_CONTRACT_KEY, serviceSvContracts);
    });
  }

  signature(editedServiceSvContract: ServiceSvContract) {
    const url = `${API_URL}/${VERSION}/ServiceSvContract/signature`;
    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline) {
      this.offlineManager.storeRequest(url, 'POST', editedServiceSvContract);
    } else {
      this.http.post(url, editedServiceSvContract).subscribe(
        response => {
          //console.log(response)
        },
        error => {
          //console.log(error)
          this.toastService.error(error.message);
          this.offlineManager.storeRequest(url, 'POST', editedServiceSvContract);
        }
      );
    }
    return from(this.signatureServiceSvContract(editedServiceSvContract));
  }

  signatureServiceSvContract(serviceSaleOrder: ServiceSvContract) {
    return this.storageService.get(SERVICE_SV_CONTRACT_KEY).then((serviceSvContract) => {
      const index = serviceSvContract.findIndex(serviceSvContract => serviceSvContract.id === serviceSaleOrder.id);
      serviceSvContract[index] = serviceSaleOrder;
      return this.storageService.set(SERVICE_SV_CONTRACT_KEY, serviceSvContract);
    });
  }

  addCpd(serviceSaleOrder: ServiceSvContract) {
    const editedServiceSvContract = { ...serviceSaleOrder }
    const url = `${API_URL}/${VERSION}/ServiceSvContract/addCpdNum`;
    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline) {
      this.offlineManager.storeRequest(url, 'POST', editedServiceSvContract);
    } else {
      this.http.post(url, editedServiceSvContract).subscribe(
        response => {
          //console.log(response)
        },
        error => {
          //console.log(error)
          this.toastService.error(error.message);
          this.offlineManager.storeRequest(url, 'POST', editedServiceSvContract);
        }
      );
    }
    return from(this.addCpdNum(editedServiceSvContract));
  }

  addCpdNum(serviceSaleOrder: ServiceSvContract) {
    return this.storageService.get(SERVICE_SV_CONTRACT_KEY).then((serviceSvContract) => {
      const index = serviceSvContract.findIndex(serviceSvContract => serviceSvContract.id === serviceSaleOrder.id);
      serviceSvContract[index] = serviceSaleOrder;
      return this.storageService.set(SERVICE_SV_CONTRACT_KEY, serviceSvContract);
    });
  }

  async generatePDF(refSV, serviceNum, dni) {
    const apiUrl = `${API_URL}/${VERSION}/ServiceSvContract/refSV/${refSV}/${serviceNum}/${dni}`;

    this.loadingService.present("Generando PDF...");

    this.http.get(apiUrl, { responseType: 'blob', observe: 'response' }).subscribe({
      next: async (response) => {
        try {
          const blob = response.body; // El archivo PDF como blob


          // Generación del nombre del archivo          
          //const ticketSubjectTruncated = ticket.ticketSubject.substring(0, 30) ?? "";
          //const fileName = `${ticketPrefixCode}_${ticket.ticketServiceSvRef ?? ""}-${ticketSubjectTruncated}.pdf`;

          const fileName = refSV + '_Resumen_Servicio.pdf';

          if (Capacitor.getPlatform() === 'android') {
            // Guardar y abrir en Android
            await this.saveAndOpenFile(blob, fileName);
          } else {
            // Descargar en Web
            this.downloadFileWeb(blob, fileName);
            // Abrir en una nueva pestaña (opcional)
            // this.openFileInNewTabWeb(blob);
          }
        } catch (e) {
          console.error('Error procesando la respuesta:', e);
        } finally {
          this.loadingService.closeLoading();
        }
      },
      error: (error) => {
        console.error('Error descargando el archivo:', error);
        this.loadingService.closeLoading();
      }
    });

  }

  async viewPDF(refSv: string, serviceNum: number, dni: string) {


    if (Capacitor.getPlatform() === 'android') {
      const fileExists = await this.fileExistsByPrefixCheck('Resumen_Servicio_' + refSv);

      if (fileExists) {

        console.log('El archivo ya existe, abriéndolo directamente...');
        this.loadingService.present("Abriendo PDF...");
        this.openFile(decodeURIComponent(fileExists));
        return; // Salir de la función, no es necesario regenerar
      } else {
        this.generatePDF(refSv, serviceNum, dni);
      }
    } else {
      this.generatePDF(refSv, serviceNum, dni);
    }

  }

  public async fileExistsByPrefixCheck(filePrefix: string): Promise<string | null> {
    try {
      // Obtener la lista de archivos en el directorio especificado
      const files = await Filesystem.readdir({
        path: '',
        directory: Directory.Documents,
      });

      // Buscar el archivo cuyo nombre comience con el prefijo dado
      const matchedFile = files.files.find(file =>
        typeof file === 'object' && 'name' in file && file.name.startsWith(filePrefix)
      );

      if (matchedFile && typeof matchedFile === 'object' && 'name' in matchedFile) {
        // Obtener información detallada del archivo encontrado
        const statResult = await Filesystem.stat({
          path: matchedFile.name, // Usamos el nombre del archivo
          directory: Directory.Documents,
        });

        console.log('Archivo encontrado:', matchedFile.name);
        return statResult.uri; // Retorna únicamente el URI del archivo como string
      } else {
        console.log('No se encontró ningún archivo con el prefijo especificado.:' + filePrefix);
        return null;
      }
    } catch (error) {
      console.log('Error al buscar el archivo:', error);
      return null; // Retornar null si ocurre algún error
    }
  }




  private async openFile(filePath: string) {

    console.log("Esta es la ruta: ", filePath);
    try {
      // Verifica si el prefijo 'file://' está presente y remuévelo
      const pathToOpen = filePath.startsWith('file://') ? filePath.replace('file://', '') : filePath;

      console.log(pathToOpen);
      //const filePath = `${Directory.Documents}/${fileName}`;
      await this.fileOpener.open(pathToOpen, 'application/pdf');
      console.log('Archivo abierto correctamente');
      this.loadingService.closeLoading();
    } catch (error) {
      console.error('Error al abrir el archivo:', error);
      this.loadingService.closeLoading();
    }
  }

  private downloadFileWeb(response: Blob, filename: string) {
    const url = window.URL.createObjectURL(response);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename; // Nombre del archivo
    a.click();
    window.URL.revokeObjectURL(url); // Limpia la URL temporal
  }

  private openFileInNewTabWeb(response: Blob) {
    const url = window.URL.createObjectURL(response);
    // Abrir el archivo en una nueva pestaña
    window.open(url, '_blank');
    // Opcionalmente, puedes revocar la URL después de un tiempo
    setTimeout(() => window.URL.revokeObjectURL(url), 5000);
  }

  private async saveAndOpenFile(response: Blob, filename: string) {
    try {
      // Convertir el archivo Blob a Base64
      const base64data = await this.convertBlobToBase64(response);

      // Guardar el archivo en el sistema de archivos local
      const fileName = filename;
      const writeResult: WriteFileResult = await Filesystem.writeFile({
        path: fileName,
        data: base64data,
        directory: Directory.Documents,
      });

      console.log('Archivo guardado en:', writeResult.uri);

      // Abrir el archivo después de guardarlo
      await this.fileOpener.open(writeResult.uri, 'application/pdf');
      console.log('Archivo abierto correctamente');
    } catch (error) {
      console.error('Error procesando el archivo:', error);
    }
  }

  private async convertBlobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve((reader.result as string).split(',')[1]); // Extraer contenido Base64
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(blob);
    });
  }

}
