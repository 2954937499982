import { Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { Absence } from 'src/app/01-models/absence';
import { AbsenceType } from 'src/app/01-models/absence-type';
import { KflowService } from 'src/app/06-core-logic/kflow-service';
import { API_URL, VERSION } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AbsenceService extends KflowService<Absence> {

  constructor() { 
    super();
    this.keyStorage = 'absences';
  }

  getAbsencesRemote() {
    const url = `${API_URL}/${VERSION}/absence/absences`;
    lastValueFrom(this.http.get<Absence[]>(url)).then((data) => {
      this.storageService.set(this.keyStorage, data);
    });
  }

  addAbsence(absence: Absence) {
    const url = `${API_URL}/${VERSION}/absence/create`;
    lastValueFrom(this.http.post<Absence>(url, absence)).then(() => {
      console.log('Absence created');
    });
    return this.addLocal(absence);
  }

  editAbsence(absence: Absence) {
    const url = `${API_URL}/${VERSION}/absence/update`;
    lastValueFrom(this.http.put<Absence>(url, absence)).then(() => {
      console.log('Absence updated');
    });
    return this.editLocal(absence);
  }

  deleteAbsence(id: number) {
    const url = `${API_URL}/${VERSION}/absence/delete/${id}`;
    lastValueFrom(this.http.delete<Absence>(url)).then(() => {
      console.log('Absence deleted');
    });
    return this.deleteLocal(id);
  }

  getAbsencesTypeRemote() {
    const url = `${API_URL}/${VERSION}/Absence/absences-type`;
    lastValueFrom(this.http.get<AbsenceType[]>(url)).then((data) => {
      this.storageService.set(this.keyStorage + '-types', data);
    });
  }

  getAbsencesTypeLocal(): Promise<AbsenceType[]> {
    return this.storageService.get(this.keyStorage + '-types');
  }
  
  async getAbsenceTypeByCodeAU(codeAU: string) {
    const absencesType = await this.getAbsencesTypeLocal();
    return absencesType.find((absenceType: AbsenceType) => absenceType.codigoAU === codeAU);
  }

  getAbsenceLocal(id: number): Promise<Absence> {
    return this.storageService.get(this.keyStorage).then((absences: Absence[]) => {
      const absence = absences.find((absence: Absence) => absence.id === id);
      return absence;
    });
  }
}
