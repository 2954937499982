import { inject, Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { API_URL, VERSION } from "../../environments/environment";
import { Observable, from, lastValueFrom } from "rxjs";
import { ServiceSvExtra } from '../01-models';
import { map } from "rxjs/operators";
import { NetworkService, ConnectionStatus } from './network.service';
import { OfflineManagerService } from './offline-manager.service';
import { ServiceSvCategory } from '../01-models/work-order-category';
import { ToastService } from './toast.service';
import { StorageService } from './storage.service';
import { EXPECTED_PRESENCES_KEY, PRESENCES_KEY, SERVICE_SV_EXTRA_KEY } from '../05-shared/utils/Contanst';
import { Filesystem, Directory, WriteFileResult, FileInfo, StatResult } from '@capacitor/filesystem';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { LoadingService } from "./loading.service";
import { Capacitor } from '@capacitor/core';

const WORK_ORDERS_CATEGORIES_KEY = 'work_categories';

@Injectable({
  providedIn: 'root'
})
export class ServiceSVExtraService {
  http = inject(HttpClient);
  networkService = inject(NetworkService);
  offlineManager = inject(OfflineManagerService);
  toastService = inject(ToastService);
  storageService = inject(StorageService);
  constructor(
    public loadingService: LoadingService,
    private fileOpener: FileOpener
  ) { }

  getServiceSvExtra(): Observable<ServiceSvExtra[]> {
    const url = `${API_URL}/${VERSION}/ServiceSvExtra/servicessvextra`;
    return this.http.get<ServiceSvExtra[]>(url).pipe(
      map(serviceSvExtras => {
        this.storageService.set(SERVICE_SV_EXTRA_KEY, serviceSvExtras);
        return serviceSvExtras;
      })
    );
  }

  getAllServiceSvExtras(): Observable<ServiceSvExtra[]> {
    return from(this.storageService.get(SERVICE_SV_EXTRA_KEY));
  }

  getAllServiceSvExtraLocal(): Observable<ServiceSvExtra[]> {
    return from(this.storageService.get(SERVICE_SV_EXTRA_KEY)).pipe(
      map(serviceSvExtras => {
        if (!serviceSvExtras) {
          return [];
        }
        return serviceSvExtras.filter(serviceSvExtra => serviceSvExtra.serviceType === 'Extra')
          .sort((a, b) => ((new Date(a.dateServiceSv.toString())) < (new Date(b.dateServiceSv.toString())) ? 1 : -1) || a.companyName.localeCompare(b.companyName));
      })
    );
  }

  getServiceSvExtraById(id: number): Observable<ServiceSvExtra> {
    return from(this.storageService.get(SERVICE_SV_EXTRA_KEY)).pipe(
      map(serviceSvExtras => serviceSvExtras.find(serviceSvExtra => serviceSvExtra.id == id))
    );
  }

  getServiceSvExtraByRef(ref: string): Observable<ServiceSvExtra> {
    return from(this.storageService.get(SERVICE_SV_EXTRA_KEY)).pipe(
      map(serviceSvExtras => serviceSvExtras.find(serviceSvExtra => serviceSvExtra.serviceSvRef == ref))
    );
  }

  getServiceSvExtraByCompanyId(companyId: number): Observable<ServiceSvExtra[]> {
    return from(this.storageService.get(SERVICE_SV_EXTRA_KEY)).pipe(
      map(serviceSvExtras => serviceSvExtras.filter(serviceSvExtra => serviceSvExtra.serviceType === 'Extra' && serviceSvExtra.companyId === companyId))
    );
  }

  getServiceSvExtrasByDni(workerDni: string): Observable<ServiceSvExtra[]> {
    return from(this.storageService.get(SERVICE_SV_EXTRA_KEY)).pipe(
      map(serviceSvExtras => serviceSvExtras.filter(serviceSvExtra => serviceSvExtra.serviceType === 'Extra' && ((serviceSvExtra.teamLeads && serviceSvExtra.teamLeads.indexOf(workerDni) !== -1) || (serviceSvExtra.team && serviceSvExtra.team.indexOf(workerDni) !== -1)))
        .sort((a, b) => ((new Date(a.dateServiceSv.toString())) < (new Date(b.dateServiceSv.toString())) ? 1 : -1) || a.companyName.localeCompare(b.companyName)))
    );
  }

  delete(serviceSvExtraId: number) {
    const url = `${API_URL}/${VERSION}/ServiceSvExtra/delete`;
    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline) {
      this.offlineManager.storeRequest(url, 'POST', serviceSvExtraId);
    } else {
      this.http.post(url, serviceSvExtraId).subscribe(
        response => {
          //console.log(response)
        },
        error => {
          //console.log(error)
          this.toastService.error(error.message);
          this.offlineManager.storeRequest(url, 'POST', serviceSvExtraId);
        }
      );
    }
    return from(this.deleteServiceSvExtra(serviceSvExtraId));
  }

  deleteServiceSvExtra(serviceSvExtraId: number) {
    return this.storageService.get(SERVICE_SV_EXTRA_KEY).then(serviceSvExtras => {
      const index = serviceSvExtras.findIndex(serviceSvExtra => serviceSvExtra.id === serviceSvExtraId);
      serviceSvExtras.splice(index, 1);
      return this.storageService.set(SERVICE_SV_EXTRA_KEY, serviceSvExtras);
    });
  }

  add(serviceSvExtra: ServiceSvExtra): Observable<any> {
    const url = `${API_URL}/${VERSION}/ServiceSvExtra/add`;
    return this.http.post(url, serviceSvExtra);
  }

  addServiceSvExtra(newServiceSvExtra: ServiceSvExtra): Observable<ServiceSvExtra> {
    return from(this.storageService.get(SERVICE_SV_EXTRA_KEY).then((serviceSvExtras: ServiceSvExtra[]) => {
      if (serviceSvExtras) {
        serviceSvExtras.unshift(newServiceSvExtra);
        return this.storageService.set(SERVICE_SV_EXTRA_KEY, serviceSvExtras);
      }
      return this.storageService.set(SERVICE_SV_EXTRA_KEY, [newServiceSvExtra]);
    }));
  }

  update(serviceSvExtra: ServiceSvExtra) {
    const url = `${API_URL}/${VERSION}/ServiceSvExtra/update`;
    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline) {
      this.offlineManager.storeRequest(url, 'POST', serviceSvExtra);
    } else {
      this.http.post(url, serviceSvExtra).subscribe(
        response => {
          //console.log(response)
        },
        error => {
          //console.log(error)
          this.toastService.error(error.message);
          this.offlineManager.storeRequest(url, 'POST', serviceSvExtra);
        }
      );
    }
    return from(this.serviceSvExtraUpdate(serviceSvExtra));
  }

  serviceSvExtraUpdate(serviceSvExtra: ServiceSvExtra) {
    return this.storageService.get(SERVICE_SV_EXTRA_KEY).then((serviceSvExtras: ServiceSvExtra[]) => {
      const index = serviceSvExtras.findIndex(serviceSvExtraItem => serviceSvExtraItem.id === serviceSvExtra.id);
      serviceSvExtras[index] = serviceSvExtra;
      return this.storageService.set(SERVICE_SV_EXTRA_KEY, serviceSvExtras);
    });
  }

  getWorkCategories(): Observable<ServiceSvCategory[]> {
    const url = `${API_URL}/${VERSION}/ServiceSvExtra/categories`;
    return this.http.get<ServiceSvCategory[]>(url).pipe(
      map(serviceSvExtrasCategories => {
        this.storageService.set(WORK_ORDERS_CATEGORIES_KEY, serviceSvExtrasCategories);
        return serviceSvExtrasCategories;
      })
    );
  }

  getServiceSvExtrasCategoriesFromLocal(): Observable<ServiceSvCategory[]> {
    return from(this.storageService.get(WORK_ORDERS_CATEGORIES_KEY));
  }

  signature(editedServiceSvExtra: ServiceSvExtra) {
    const url = `${API_URL}/${VERSION}/ServiceSvExtra/signature`;
    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline) {
      this.offlineManager.storeRequest(url, 'POST', editedServiceSvExtra);
    } else {
      this.http.post(url, editedServiceSvExtra).subscribe(
        response => {
          //console.log(response)
        },
        error => {
          //console.log(error)
          this.toastService.error(error.message);
          this.offlineManager.storeRequest(url, 'POST', editedServiceSvExtra);
        }
      );
    }
    return from(this.signatureServiceSvExtra(editedServiceSvExtra));
  }

  signatureServiceSvExtra(serviceSvExtra: ServiceSvExtra) {
    return this.storageService.get(SERVICE_SV_EXTRA_KEY).then((serviceSvExtras) => {
      const index = serviceSvExtras.findIndex(serviceSvExtras => serviceSvExtras.id === serviceSvExtra.id);
      serviceSvExtras[index] = serviceSvExtra;
      return this.storageService.set(SERVICE_SV_EXTRA_KEY, serviceSvExtras);
    });
  }

  async serviceSvExtraStatusUpdate(serviceSvRef: string, cpdStatusId: string = '00') {
    if (serviceSvRef.includes('CON')) {
      return;
    }
    try {
      const updatedService = await this.serviceSvExtraValidateState(serviceSvRef, cpdStatusId);
      return this.serviceSvExtraUpdate(updatedService).then();
    } catch (error) {
      console.error('Error al actualizar el estado del servicio(SV):', error);
      this.toastService.error('Error al actualizar el estado del servicio(SV).');
    }
  }


  async serviceSvExtraValidateState(ref: string, cpdStatusId: string = '00') {
    try {
      const serviceSvExtra = await lastValueFrom(this.getServiceSvExtraByRef(ref));
      if (!serviceSvExtra) {
        throw new Error(`No se encontró el servicio con referencia: ${ref}`);
      }
      const presences = await lastValueFrom(this.getPresencesByServiceRef(ref));
      const expectedPresences = await lastValueFrom(this.getExpectedPresencesByServiceRef(ref));

      const totalHoursPresences = presences.reduce((acc, presence) => acc + presence.workedHours, 0);
      const totalHoursExpectedPresences = expectedPresences.reduce((acc, presence) => acc + presence.expectedHours, 0);

      if (cpdStatusId !== '00') {
        serviceSvExtra.serviceSVStatusId = serviceSvExtra.cpdStatusId;
        serviceSvExtra.cpdStatusId = cpdStatusId;
      } else {
        switch (true) {
          case totalHoursPresences < totalHoursExpectedPresences:
            serviceSvExtra.serviceSVStatusId = '02';
            break;
          case totalHoursPresences > 0 && totalHoursExpectedPresences === 0:
            serviceSvExtra.serviceSVStatusId = '03';
            break;
          case totalHoursPresences > 0 && totalHoursExpectedPresences === totalHoursPresences:
            serviceSvExtra.serviceSVStatusId = '03';
            break;
          default:
            serviceSvExtra.serviceSVStatusId = '01';
            break;
        }
      }
      return serviceSvExtra;
    } catch (error) {
      console.error('Error en serviceSvValidateState:', error);
      throw error;
    }
  }

  getPresencesByServiceRef(serviceSvRef: string) {
    return from(this.storageService.get(PRESENCES_KEY)).pipe(
      map(presences => presences.filter(presence => presence.serviceSvRef == serviceSvRef))
    );
  }

  getExpectedPresencesByServiceRef(serviceSvRef: string) {
    return from(this.storageService.get(EXPECTED_PRESENCES_KEY)).pipe(
      map(presences => presences.filter(presence => presence.serviceSvRef == serviceSvRef))
    );
  }

  serviceSvExtraCloseByAdministration(RefSVCPR: string) {
    const url = `${API_URL}/${VERSION}/ServiceSvExtra/ServicioExtraCerrarPorAdministracion`;
    return this.http.post(url, { RefSVCPR });
  }


  async generatePDF(refSV) {
    const apiUrl = `${API_URL}/${VERSION}/ServiceSvExtra/refSV/${refSV}`;

    this.loadingService.present("Generando PDF...");

    this.http.get(apiUrl, { responseType: 'blob', observe: 'response' }).subscribe({
      next: async (response) => {
        try {
          const blob = response.body; // El archivo PDF como blob


          // Generación del nombre del archivo          
          //const ticketSubjectTruncated = ticket.ticketSubject.substring(0, 30) ?? "";
          //const fileName = `${ticketPrefixCode}_${ticket.ticketServiceSvRef ?? ""}-${ticketSubjectTruncated}.pdf`;

          const fileName = refSV + '_Resumen_Servicio.pdf';

          if (Capacitor.getPlatform() === 'android') {
            // Guardar y abrir en Android
            await this.saveAndOpenFile(blob, fileName);
          } else {
            // Descargar en Web
            this.downloadFileWeb(blob, fileName);
            // Abrir en una nueva pestaña (opcional)
            // this.openFileInNewTabWeb(blob);
          }
        } catch (e) {
          console.error('Error procesando la respuesta:', e);
        } finally {
          this.loadingService.closeLoading();
        }
      },
      error: (error) => {
        console.error('Error descargando el archivo:', error);
        this.loadingService.closeLoading();
      }
    });

  }

  async viewPDF(refSv: string) {


    if (Capacitor.getPlatform() === 'android') {
      const fileExists = await this.fileExistsByPrefixCheck(refSv + '_Resumen_Servicio');

      if (fileExists) {

        console.log('El archivo ya existe, abriéndolo directamente...');
        this.loadingService.present("Abriendo PDF...");
        this.openFile(decodeURIComponent(fileExists));
        return; // Salir de la función, no es necesario regenerar
      } else {
        this.generatePDF(refSv);
      }
    } else {
      this.generatePDF(refSv);
    }

  }

  public async fileExistsByPrefixCheck(filePrefix: string): Promise<string | null> {
    try {
      // Obtener la lista de archivos en el directorio especificado
      const files = await Filesystem.readdir({
        path: '',
        directory: Directory.Documents,
      });

      // Buscar el archivo cuyo nombre comience con el prefijo dado
      const matchedFile = files.files.find(file =>
        typeof file === 'object' && 'name' in file && file.name.startsWith(filePrefix)
      );

      if (matchedFile && typeof matchedFile === 'object' && 'name' in matchedFile) {
        // Obtener información detallada del archivo encontrado
        const statResult = await Filesystem.stat({
          path: matchedFile.name, // Usamos el nombre del archivo
          directory: Directory.Documents,
        });

        console.log('Archivo encontrado:', matchedFile.name);
        return statResult.uri; // Retorna únicamente el URI del archivo como string
      } else {
        console.log('No se encontró ningún archivo con el prefijo especificado.:' + filePrefix);
        return null;
      }
    } catch (error) {
      console.log('Error al buscar el archivo:', error);
      return null; // Retornar null si ocurre algún error
    }
  }




  private async openFile(filePath: string) {

    console.log("Esta es la ruta: ", filePath);
    try {
      // Verifica si el prefijo 'file://' está presente y remuévelo
      const pathToOpen = filePath.startsWith('file://') ? filePath.replace('file://', '') : filePath;

      console.log(pathToOpen);
      //const filePath = `${Directory.Documents}/${fileName}`;
      await this.fileOpener.open(pathToOpen, 'application/pdf');
      console.log('Archivo abierto correctamente');
      this.loadingService.closeLoading();
    } catch (error) {
      console.error('Error al abrir el archivo:', error);
      this.loadingService.closeLoading();
    }
  }

  private downloadFileWeb(response: Blob, filename: string) {
    const url = window.URL.createObjectURL(response);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename; // Nombre del archivo
    a.click();
    window.URL.revokeObjectURL(url); // Limpia la URL temporal
  }

  private openFileInNewTabWeb(response: Blob) {
    const url = window.URL.createObjectURL(response);
    // Abrir el archivo en una nueva pestaña
    window.open(url, '_blank');
    // Opcionalmente, puedes revocar la URL después de un tiempo
    setTimeout(() => window.URL.revokeObjectURL(url), 5000);
  }

  private async saveAndOpenFile(response: Blob, filename: string) {
    try {
      // Convertir el archivo Blob a Base64
      const base64data = await this.convertBlobToBase64(response);

      // Guardar el archivo en el sistema de archivos local
      const fileName = filename;
      const writeResult: WriteFileResult = await Filesystem.writeFile({
        path: fileName,
        data: base64data,
        directory: Directory.Documents,
      });

      console.log('Archivo guardado en:', writeResult.uri);

      // Abrir el archivo después de guardarlo
      await this.fileOpener.open(writeResult.uri, 'application/pdf');
      console.log('Archivo abierto correctamente');
    } catch (error) {
      console.error('Error procesando el archivo:', error);
    }
  }

  private async convertBlobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve((reader.result as string).split(',')[1]); // Extraer contenido Base64
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(blob);
    });
  }
}
