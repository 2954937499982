import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { API_URL, VERSION } from "../../environments/environment";
import { Observable, from, lastValueFrom, of } from "rxjs";
import { Ticket } from '../01-models';
import { map } from "rxjs/operators";
import { LoadingService } from "./loading.service";
import { NetworkService, ConnectionStatus } from './network.service';
import { OfflineManagerService } from './offline-manager.service';
import { ToastService } from './toast.service';
import { StorageService } from './storage.service';
import { GalleryMedia } from '../01-models/gallery-media';
import { MEDIA } from '../05-shared/utils/Contanst';
import { tick } from '@angular/core/testing';
import { Filesystem, Directory, WriteFileResult, FileInfo, StatResult } from '@capacitor/filesystem';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { Capacitor } from '@capacitor/core';


const TICKETS_KEY = 'tickets';
const TICKETS_IMAGE_KEY = 'tickets-image';

@Injectable({
  providedIn: 'root'
})
export class TicketService {

  constructor(
    private http: HttpClient,
    private networkService: NetworkService,
    private offlineManager: OfflineManagerService,
    private toastService: ToastService,
    private storageService: StorageService,
    public loadingService: LoadingService,
    private fileOpener: FileOpener
  ) { }

  getTickets(): Observable<Ticket[]> {
    const url = `${API_URL}/${VERSION}/ticket/tickets`;
    return this.http.get<Ticket[]>(url).pipe(
      map(tickets => {
        this.storageService.set(TICKETS_KEY, tickets);
        return tickets;
      })
    );
  }

  getAllTickets(): Promise<Ticket[]> {
    return this.storageService.get(TICKETS_KEY);
  }

  getTicketById(id: number): Observable<Ticket> {
    return from(this.storageService.get(TICKETS_KEY)).pipe(
      map(tickets => tickets.find(ticket => ticket.id == id))
    );
  }

  getTicketByTmpId(tmpId: string): Observable<Ticket> {
    return from(this.storageService.get(TICKETS_KEY)).pipe(
      map(tickets => tickets.find(ticket => ticket.tmpTktId == tmpId))
    );
  }

  getTicketsByWorkplace(companyId: number, workplaceNum: number): Observable<Ticket[]> {
    return from(this.storageService.get(TICKETS_KEY)).pipe(
      map(tickets => tickets.filter(ticket => ticket.companyId == companyId && ticket.workplaceNum == workplaceNum))
    );
  }

  async add(ticket: Ticket): Promise<Ticket> {
    const url = `${API_URL}/${VERSION}/ticket/add`;
    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline) {
      this.offlineManager.storeRequest(url, 'POST', ticket);
      return this.addTicket(ticket);
    } else {
      try {
        const ticketNew = await lastValueFrom(this.http.post(url, ticket));
        await this.addTicket(ticketNew);
        return ticketNew;
      } catch (error) {
        //console.log(error)
        this.toastService.error(error.message);
        this.offlineManager.storeRequest(url, 'POST', ticket);
      }
    }
  }

  addImage(ticket: Ticket, images: GalleryMedia[]) {
    const url = `${API_URL}/${VERSION}/GalleryMedia/add`;
    const formData = new FormData();
    for (let image of images) {

      const imageGalleryMedia: GalleryMedia = {
        file: image.file,
        fileName: image.fileName,
        mediaType: MEDIA.IMAGE,
        mediaDescription: image.mediaDescription,
        mediaStatus: 'vigente',
        relatedTable: 'ticket',
        relatedTableId: ticket.id,
        modifiedBy: image.modifiedBy
      }

      formData.append('file', imageGalleryMedia.file);
      formData.append('fileName', imageGalleryMedia.fileName);
      formData.append('mediaType', imageGalleryMedia.mediaType);
      formData.append('mediaDescription', imageGalleryMedia.mediaDescription);
      formData.append('mediaStatus', imageGalleryMedia.mediaStatus);
      formData.append('relatedTable', imageGalleryMedia.relatedTable);
      formData.append('relatedTableId', imageGalleryMedia.relatedTableId.toString());
      formData.append('modifiedBy', imageGalleryMedia.modifiedBy);
    }
    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline) {
      this.offlineManager.storeRequest(url, 'POST', formData);
    } else {
      lastValueFrom(this.http.post(url, formData
      )).then(
        response => {
          //console.log(response)
        }).catch(error => {
          //console.log(error)
          this.toastService.error(error.message);
          this.offlineManager.storeRequest(url, 'POST', formData);
        }
        );
    }
    //return from(this.addImageByTicketId(ticket.id, images));
    // TODO: Implmentar metodo para guardar en el storage local
    return of(true);
  }

  convertToFile(image: string, name: string) {
    const byteCharacters = atob(image.split(',')[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new File([byteArray], name, { type: 'image/jpeg' });
  }

  addTicket(newTicket: Ticket) {
    return this.storageService.get(TICKETS_KEY).then(async (tickets: any[]) => {
      if (tickets) {
        tickets.unshift(newTicket);
        await this.storageService.set(TICKETS_KEY, tickets);
        return newTicket;
      }
      await this.storageService.set(TICKETS_KEY, [newTicket]);
      return newTicket;
    });
  }

  addImageByTicketId(id: number, image: { name: string, imageBase64: string }[]) {
    /**return this.storageService.get(TICKETS_IMAGE_KEY).then((tickets: {id: number, image: {name: string, imageBase64: string}[]}[]) => {
      if (tickets) {
        tickets.unshift({ id, image });
        return this.storageService.set(TICKETS_IMAGE_KEY, tickets);
      }
      return this.storageService.set(TICKETS_IMAGE_KEY, [{ id, image }]);
    });**/
  }

  update(ticket: Ticket) {
    const url = `${API_URL}/${VERSION}/ticket/update`;
    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline) {
      this.offlineManager.storeRequest(url, 'POST', ticket);
    } else {
      this.http.post(url, ticket).subscribe(
        response => {
          //console.log(response)
        },
        error => {
          //console.log(error)
          this.toastService.error(error.message);
          this.offlineManager.storeRequest(url, 'POST', ticket);
        }
      );
    }
    return from(this.updateTicket(ticket));
  }

  updateTicket(editedTicket: Ticket) {
    return this.storageService.get(TICKETS_KEY).then((tickets: any[]) => {
      const index = tickets.findIndex(tickets => tickets.id === editedTicket.id);
      tickets[index] = editedTicket;
      return this.storageService.set(TICKETS_KEY, tickets);
    });
  }

  delete(ticket: Ticket) {
    const url = `${API_URL}/${VERSION}/ticket/delete`;
    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline) {
      this.offlineManager.storeRequest(url, 'POST', ticket);
    } else {
      this.http.post(url, ticket).subscribe(
        response => {
          //console.log(response)
        },
        error => {
          //console.log(error)
          this.toastService.error(error.message);
          this.offlineManager.storeRequest(url, 'POST', ticket);
        }
      );
    }
    return from(this.deleteTicket(ticket.id));
  }

  deleteTicket(ticketId: number) {
    return this.storageService.get(TICKETS_KEY).then(tickets => {
      const index = tickets.findIndex(ticket => ticket.id === ticketId);
      tickets.splice(index, 1);

      return this.storageService.set(TICKETS_KEY, tickets);
    });
  }

  checkImageExists(imageUrl: string): Promise<boolean> {
    return lastValueFrom(this.http.get(imageUrl, { observe: 'response' }))
      .then(response => response.status === 200)
      .catch(() => false);
  }

  async generatePDF(ticketPrefixCode, ticket: Ticket) {
    const apiUrl = `${API_URL}/${VERSION}/ticket/${ticket.id}`;

    this.loadingService.present("Generando PDF...");

    this.http.get(apiUrl, { responseType: 'blob', observe: 'response' }).subscribe({
      next: async (response) => {
        try {
          const blob = response.body; // El archivo PDF como blob


          // Generación del nombre del archivo          
          const ticketSubjectTruncated = ticket.ticketSubject.substring(0, 30) ?? "";
          const fileName = `${ticketPrefixCode}_${ticket.ticketServiceSvRef ?? ""}-${ticketSubjectTruncated}.pdf`;

          if (Capacitor.getPlatform() === 'android') {
            // Guardar y abrir en Android
            await this.saveAndOpenFile(blob, fileName);
          } else {
            // Descargar en Web
            this.downloadFileWeb(blob, fileName);
            // Abrir en una nueva pestaña (opcional)
            // this.openFileInNewTabWeb(blob);
          }
        } catch (e) {
          console.error('Error procesando la respuesta:', e);
        } finally {
          this.loadingService.closeLoading();
        }
      },
      error: (error) => {
        console.error('Error descargando el archivo:', error);
        this.loadingService.closeLoading();
      }
    });

  }

  async viewPDF(ticketPrefixCode: string, ticket: Ticket) {


    if (Capacitor.getPlatform() === 'android') {
      const fileExists = await this.fileExistsByPrefixCheck(ticketPrefixCode);

      if (fileExists) {
        console.log('El archivo ya existe, abriéndolo directamente...');
        this.openFile(decodeURIComponent(fileExists));
        return; // Salir de la función, no es necesario regenerar
      } else {
        this.generatePDF(ticketPrefixCode, ticket);
      }
    } else {
      this.generatePDF(ticketPrefixCode, ticket);
    }

  }

  public async fileExistsByPrefixCheck(filePrefix: string): Promise<string | null> {
    try {
      // Obtener la lista de archivos en el directorio especificado
      const files = await Filesystem.readdir({
        path: '',
        directory: Directory.Documents,
      });

      // Buscar el archivo cuyo nombre comience con el prefijo dado
      const matchedFile = files.files.find(file =>
        typeof file === 'object' && 'name' in file && file.name.startsWith(filePrefix)
      );

      if (matchedFile && typeof matchedFile === 'object' && 'name' in matchedFile) {
        // Obtener información detallada del archivo encontrado
        const statResult = await Filesystem.stat({
          path: matchedFile.name, // Usamos el nombre del archivo
          directory: Directory.Documents,
        });

        console.log('Archivo encontrado:', matchedFile.name);
        return statResult.uri; // Retorna únicamente el URI del archivo como string
      } else {
        console.log('No se encontró ningún archivo con el prefijo especificado.:' + filePrefix);
        return null;
      }
    } catch (error) {
      console.log('Error al buscar el archivo:', error);
      return null; // Retornar null si ocurre algún error
    }
  }




  private async openFile(filePath: string) {

    console.log("Esta es la ruta: ", filePath);
    try {
      // Verifica si el prefijo 'file://' está presente y remuévelo
      const pathToOpen = filePath.startsWith('file://') ? filePath.replace('file://', '') : filePath;

      console.log(pathToOpen);
      //const filePath = `${Directory.Documents}/${fileName}`;
      await this.fileOpener.open(pathToOpen, 'application/pdf');
      console.log('Archivo abierto correctamente');
    } catch (error) {
      console.error('Error al abrir el archivo:', error);
    }
  }

  private downloadFileWeb(response: Blob, filename: string) {
    const url = window.URL.createObjectURL(response);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename; // Nombre del archivo
    a.click();
    window.URL.revokeObjectURL(url); // Limpia la URL temporal
  }

  private openFileInNewTabWeb(response: Blob) {
    const url = window.URL.createObjectURL(response);
    // Abrir el archivo en una nueva pestaña
    window.open(url, '_blank');
    // Opcionalmente, puedes revocar la URL después de un tiempo
    setTimeout(() => window.URL.revokeObjectURL(url), 5000);
  }

  private async saveAndOpenFile(response: Blob, filename: string) {
    try {
      // Convertir el archivo Blob a Base64
      const base64data = await this.convertBlobToBase64(response);

      // Guardar el archivo en el sistema de archivos local
      const fileName = filename;
      const writeResult: WriteFileResult = await Filesystem.writeFile({
        path: fileName,
        data: base64data,
        directory: Directory.Documents,
      });

      console.log('Archivo guardado en:', writeResult.uri);

      // Abrir el archivo después de guardarlo
      await this.fileOpener.open(writeResult.uri, 'application/pdf');
      console.log('Archivo abierto correctamente');
    } catch (error) {
      console.error('Error procesando el archivo:', error);
    }
  }

  private async convertBlobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve((reader.result as string).split(',')[1]); // Extraer contenido Base64
      reader.onerror = (error) => reject(error);
      reader.readAsDataURL(blob);
    });
  }

}
