import { HttpClient } from "@angular/common/http";
import { inject } from "@angular/core";
import { NetworkService } from "../02-services/network.service";
import { OfflineManagerService } from "../02-services/offline-manager.service";
import { StorageService } from "../02-services/storage.service";

export class KflowService<Model> {

    keyStorage: string;

    http = inject(HttpClient);
    networkService = inject(NetworkService);
    offlineManager = inject(OfflineManagerService);
    storageService = inject(StorageService);

    getListLocal(sortBy?: (a: Model, b: Model) => number) {
        return this.storageService.get(this.keyStorage).then((data: Model[]) => {
            if (sortBy && typeof sortBy === 'function') {
                return data.sort(sortBy);
            }
            return data;
        });
    }

    getListLocalByParam(param: keyof Model, value: any, sortBy?: (a: Model, b: Model) => number) {
        return this.storageService.get(this.keyStorage).then((data: Model[]) => {
            const filteredData = data.filter(item => item[param] === value);
            if (sortBy && typeof sortBy === 'function') {
                return filteredData.sort(sortBy);
            }
            return filteredData;
        });
    }

    addLocal(model: Model) {
        return this.storageService.get(this.keyStorage).then(async (data: Model[]) => {
            if (data) {
                data.unshift(model);
                await this.storageService.set(this.keyStorage, data);
            } else {
                await this.storageService.set(this.keyStorage, [data]);
            }
            return model;
        });
    }

    editLocal(model: Model) {
        return this.storageService.get(this.keyStorage).then(async (data: Model[]) => {
            const index = model['id'] ? data.findIndex(item => item['id'] === model['id']) : data.findIndex(item => item['tmpId'] === model['tmpId']);
            data[index] = model;
            await this.storageService.set(this.keyStorage, data);
            return model;
        });
    }

    deleteLocal(id: number) {
        return this.storageService.get(this.keyStorage).then(async (data: Model[]) => {
            const index = data.findIndex(item => item['id'] === id);
            data.splice(index, 1);
            await this.storageService.set(this.keyStorage, data);
            return id;
        });
    }
}