export const MINUTE_VALUES = '0,5,10,15,20,25,30,35,40,45,50,55';
export const STATUSES = [{ id: '01', name: 'Registrada por operario' }];


export const MONTH_NAMES = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
];
export const MONTH_NAMES_SHORT = [
  'Ene',
  'Feb',
  'Mar',
  'Abr',
  'May',
  'Jun',
  'Jul',
  'Ago',
  'Sep',
  'Oct',
  'Nov',
  'Dic',
];

export const DAY_NAMES = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];



export const DAY_NAMES_SHORT = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];

/**
 * Tareas Ciclo de vida
 * 
 * Verde, Green En Planificación significa que la tarea fué correctamente asignada, pero todavía no fué vista por su responsable o empleado
 *
 * Amarilla Yellow Pendientes INICIO ejecución Planificadas  asignadas
 *
 * Gris,   en ejecución las tareas están detenidas o fueron completadas, con la excepción que estas últimas, también están tachadas
 * Negro,  Black fINALIINAZADA Trabajada significa se ha terminado
 *
 * Naranja,  Warning  Pendiente Atencion tareas están esperando el control del Cliente
 * Rojo,     Danger significa que esa tarea caducó, Atención error  es decir que excedió su fecha límite
 * 
 * Azul,  Aceptada empleado o autorizada
 */
// TODO: Añadir o adaptar estos colores en el logo Kflow ciclo de vida de las tareas
export const TASK_LIFECYCLE = {
  PLANING: '#74d14c', // green
  PENDING: '#e0b500', // yellow
  IN_PROGRESS: '#8b8b8b', // gray 
  WARNING: '#f2af58', // orange
  DANGER: '#f04141', // red
  FINISHED: '#4c96d7',// blue
  INVOICED: '#000000', // black
  CLOSED: '#000000' // black
}


export const PRESENCES_KEY = 'presences';
export const EXPECTED_PRESENCES_KEY = 'expected-presences';

export const TICKET_STATUS_NAMES = {
  1: '01-Abierto',
  2: '02-En resolución',
  3: '03-Cerrado',
  4: '04-Cancelado',
};

export const TICKET_STATUS_COLOR = {
  1: '#e0b500',// yellow
  2: '#8b8b8b', // gray 
  3: '#000000', // black
  4: '#000000' // black
}

export const logging = (message: any) => {
  console.log('Logging', message);
}

export const SERVICE_SV_EXTRA_KEY = 'service-sv-extra';

export const MEDIA = {
  IMAGE: 'Imagen',
  VIDEO: 'Video',
  AUDIO: 'Audio',
  DOCUMENT: 'Documento',
  OTHER: 'Otro',
}

export const SERVICE_SV_EXTRA_ACTIONS = {
  REGISTER_EXPECTED_PRESENCE_SELECTED: 'register-expected-presence-selected',
  EDIT_EXPECTED_PRESENCE_SELECTED: 'edit-expected-presence-selected',
  DELETE_EXPECTED_PRESENCE_SELECTED: 'delete-expected-presence-selected',
  EDIT_PRESENCE_SELECTED: 'edit-presence-selected',
  DELETE_PRESENCE_SELECTED: 'delete-presence-selected',
  ADD_EXPECTED_PRESENCE_INDIVIDUAL: 'add-expected-presence-individual',
  ADD_EXPECTED_PRESENCE_MANY: 'add-expected-presence-many',
  VALIDATE_PRESENCE_BY_LEAD: 'validate-presence-by-lead',
  CLOSE_SERVICE_BY_ADMINISTRATOR: 'close-service-by-administrator',
  FOTTER_REGISTER: 'fotter-register',
  FOTTER_CONFIRM: 'fotter-confirm',
  FOTTER_ACTIONS: 'fotter-actions',
  FOTTER_SAVE: 'fotter-save',
  FOOTER_SERVICE_SV_EDIT: 'footer-service-sv-edit',
  FOTTER_EXPECTED_PRESENCE_ADD: 'fotter-expected-presence-add',
  FOOTER_PDF: 'footer-pdf',
  GENERATE_CPD_PDF: 'generate-pdf',
  VIEW_CPD_PDF: 'view-pdf',
  GENERATE_SERVICE_REPORT_PDF: 'service-report-pdf',
  SERVICE_REPORT: 'service-report',
  CPD_PDF: 'CPD-Pdf'
}

export const PRESENCE_EXPECTED_MASSIVE = {
  CREATE_MANY: 'create_many',
  EDIT_MANY: 'edit_many',
}

export const ABSENCE_DETAIL_ACTIONS = {
  SAVE: 'save',
  EDIT: 'edit',
}

export const ROUTE_ACTIONS = {
  NEW: 'new',
  EDIT: 'edit',
  VIEW: 'view',
}
