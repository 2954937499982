import { inject, Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { API_URL, VERSION } from "../../environments/environment";
import { Observable, from } from "rxjs";
import { Notification } from '../01-models';
import { map } from "rxjs/operators";
import { NetworkService, ConnectionStatus } from './network.service';
import { OfflineManagerService } from './offline-manager.service';
import { ToastService } from './toast.service';
import { StorageService } from './storage.service';
import { BehaviorSubject } from 'rxjs';

const NOTIFICATIONS_KEY = 'notifications';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private _unreadCount = new BehaviorSubject<number>(0);
  unreadCount$ = this._unreadCount.asObservable();
  private http = inject(HttpClient);
  private networkService = inject(NetworkService);
  private offlineManager = inject(OfflineManagerService); 
  private toastService = inject(ToastService);
  private storageService = inject(StorageService);

  constructor() { }

  incrementUnreadCount() {
    this._unreadCount.next(this._unreadCount.value + 1);
  }

  setUnreadCount(count: number) {
    this._unreadCount.next(count);
  }

  resetUnreadCount() {
    this._unreadCount.next(0);
  }
  getNotifications(): Observable<Notification[]> {
    const url = `${API_URL}/${VERSION}/notification/notifications`;
    return this.http.get<Notification[]>(url).pipe(
      map(notifications => {
        this.storageService.set(NOTIFICATIONS_KEY, notifications);
        return notifications;
      })
    );
  }

  getAllNotifications(): Observable<Notification[]> {
    return from(this.storageService.get(NOTIFICATIONS_KEY));
  }

  getNotificationById(id: number): Observable<Notification> {
    return from(this.storageService.get(NOTIFICATIONS_KEY)).pipe(
      map(notifications => notifications.find(notification => notification.id == id))
    );
  }

  update(notification: Notification) {
    const url = `${API_URL}/${VERSION}/notification/update`;
    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline) {
      this.offlineManager.storeRequest(url, 'POST', notification);
    } else {
      this.http.post(url, notification).subscribe(
        response => {
          //console.log(response)
        },
        error => {
          //console.log(error)
          this.toastService.error(error.message);
          this.offlineManager.storeRequest(url, 'POST', notification);
        }
      );
    }
    return from(this.updateNotification(notification));
  }

  updateNotification(editedNotification: Notification) {
    return this.storageService.get(NOTIFICATIONS_KEY).then((notifications: any[]) => {
      const index = notifications.findIndex(notification => notification.id === editedNotification.id);
      notifications[index] = editedNotification;
      return this.storageService.set(NOTIFICATIONS_KEY, notifications);
    });
  }

  delete(id: number) {
    const url = `${API_URL}/${VERSION}/notification/delete`;
    if (this.networkService.getCurrentNetworkStatus() == ConnectionStatus.Offline) {
      this.offlineManager.storeRequest(url, 'POST', id);
    } else {
      this.http.post(url, id).subscribe(
        response => {
          //console.log(response)
        },
        error => {
          //console.log(error)
          this.toastService.error(error.message);
          this.offlineManager.storeRequest(url, 'POST', id);
        }
      );
    }
    return from(this.deleteNotification(id));
  }

  deleteNotification(id: number) {
    return this.storageService.get(NOTIFICATIONS_KEY).then(notifications => {
      const index = notifications.findIndex(notification => notification.id === id);
      notifications.splice(index, 1);
      return this.storageService.set(NOTIFICATIONS_KEY, notifications);
    });
  }
}

